.divider {
	width: 100%;
	height: 1px;
	margin-bottom: 20px;
	background-color: var(--bg-color-2);
}

.titleHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.titleHeader .headerLabel {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	color: var(--text-color-3);
}

.switcherWrapper {
	display: flex;
	width: 150px;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	right: 20px;
}

.main {
	display: flex;
	flex-direction: column;
	margin-top: 25px;
}

.row1 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 23px;
	min-height: 615px;
}

.cardHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.preloader_custom {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.preloader_custom .ant-spin {
	color: var(--text-color-1) !important;
}

.cardTitle {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
}

.sticky {
	position: sticky;
	top: -1px;
	background: var(--bg-color-2);
	padding: 18px 0 13px;
}

.cardDescription {
	margin-top: 10px;
	font-weight: bold;
	font-size: 18px;
	line-height: 35px;
	color: var(--text-color-1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.burnRate {
	margin-top: 34px;
}

.percentChartWrapper {
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
}

.percentChartWrapper :global .apexcharts-legend-marker {
	border-width: 2px;
	margin-right: 10px;
	/* color: #a5484823; */
}

.percentChartWrapper :global .apexcharts-legend-series {
	display: flex;
	align-items: center;
	margin-bottom: 20px !important;
}

.percentChartWrapper :global .apexcharts-xaxis-label {
	color: red;
}

.burnRateChartWrapper {
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
}

.burnRateChartWrapper1 {
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-start;
}

.tableHeader {
	margin-top: 26px;
	margin-left: 16px;
	padding-bottom: 10px;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: var(--text-color-3);
	border-bottom: 1px solid rgba(66, 96, 166, 0.2);
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 2fr;
}

.stickyHeader {
	position: sticky;
	top: 50px;
	background: var(--bg-color-2);
	padding-top: 20px;
	margin-top: 0px;
}

.tableRow {
	height: 70px;
	/* padding: 6px; */
	margin-left: 16px;
	border-bottom: 1px solid rgba(122, 123, 151, 0.3);
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 2fr;
	align-items: center;
	color: var(--text-color-1);
}

.tableRow a {
	color: var(--text-color-1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}

.tableRow:last-child {
	margin-bottom: 0;
}

.loadBtnWrapper {
	margin-top: 24px;
	display: flex;
	justify-content: center;
}

.loadBtn {
	align-items: center;
	text-align: center;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	color: var(--text-color-3);
	border: 2px solid var(--text-color-3);
	border-radius: 100px;
	padding: 6px 24px 6px 24px;
	cursor: pointer;
}

.tableWrapper {
	margin-top: 20px;
	padding: 0px 18px 18px 16px;
	max-height: 700px;
	overflow: hidden;
}

.allRows {
	overflow: auto;
	max-height: 580px;
}

.tableWrapper::-webkit-scrollbar {
	/* display: none; */
}

.tableInner {
	/* min-width: 1000px; */
}

.linkWrapper {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.innerWrapper {
	min-height: 450px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.innerWrapper > div:first-child {
	max-height: 500px !important;
	width: 100% !important;
}

.innerWrapperDonut {
	min-height: 450px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.innerWrapperDonut > div:first-child {
	max-height: 500px !important;
	width: 100% !important;
}

.dontHave {
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: var(--text-color-3);
}

.firstLine {
	font-weight: bold !important;
}

.table .icon svg {
	width: 24px;
	height: 24px;
}

.amountWrapper {
	display: flex;
	align-items: center;
}

.amountWrapper .icon svg {
	margin-right: 10px;
	width: 28px;
	height: 28px;
}

.tableRow p {
	margin: 0;
	font-weight: normal;
	font-size: 16px;
	color: var(--text-color-1);
}

.amountWrapper .value {
	font-weight: 600;
	font-size: 16px;
}

.wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.icon {
	height: 28px;
	margin-right: 7px;
}
.icon svg {
	width: 28px;
	height: 28px;
}

@media (max-width: 1000px) {
	.row1 {
		display: flex;
		flex-direction: column;
	}

	.tableWrapper {
		overflow-x: scroll;
	}

	.innerWrapper {
		min-width: 500px;
	}

	.innerWrapperDonut {
		min-height: 300px;
		min-width: 500px;
	}
}

@media (max-width: 600px) {
	.percentChartWrapper,
	.burnRateChartWrapper,
	.burnRateChartWrapper1 {
		overflow-x: scroll;
	}
}
