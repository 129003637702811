.component {
    font-family: Poppins;
    background: var(--bg-color-15);
    /*border: 2px solid var(--bg-color-4);*/
    box-sizing: border-box;
    border-radius: 16px;
    padding: 18px 18px 18px 16px;
    position: relative;
}

.customTooltip {
    position: absolute;
    right: 18px;
}
