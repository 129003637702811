.card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  max-width: 900px;
  border-collapse: collapse;
  box-sizing: border-box;
  overflow: hidden;
}

.tableHead {
  border-collapse: collapse;
}

.tableBody {
  border-top: 1px solid var(--bg-color-9);
  border-collapse: collapse;
  margin-top: 30px;
}

.divider {
  border-bottom: 1px solid var(--bg-color-9);
}

.title {
  font-size: 16px;
  padding-bottom: 10px;
  line-height: 18px;
  font-weight: 700;
  color: var(--text-color-3);
  width: 36% !important;
}

.tableCell {
  padding-top: 30px; 
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-color-1);
}

.coinEtherum {
  width: 100%;
  display: flex;
}

.statNumber {
  margin-top: 5px;
  margin-left: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-color-1);
}

a  {
  text-decoration: underline !important;
  color: var(--text-color-15);
}

.loading {
  justify-content: center;
  display: flex !important;
  margin-top: 0.8rem;
}

.btnLoad {
  background: transparent!important;
  font-size: 15px;
  line-height: 18px;
  color: var(--text-color-3);
  width: 140px;
  height: 31px;
  border: 2px solid var(--border-color-6);
  border-radius: 100px;
}

/* RESPONSIVE*/

.ant-row {
  width: 100% !important;
  display: flex;
  padding-bottom: 1rem;
}

.cardMobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

@media (max-width: 639px){
  .card {
    display: none;
  }
  
  .title {
    width: 100% !important;
    padding-bottom: 0rem;
    padding-left: 0rem!important;
  }

  .tableCell {
    padding-top: 0px !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem;
  }

  .statNumber {
    padding-left: 0rem!important;
  }
}

@media (min-width: 639px){
  .cardMobile {
    display: none;
  }

  .btnLoadMobile {
    display: none;
  }
}

 


  


