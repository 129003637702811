.component {
    height: 48px;
    display: flex;
    flex-direction: row;
    background-color: var(--bg-color-15);
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
}
.component:hover,.component:focus{
    background-color: var(--bg-color-15);
}

.label {
    margin-right: 8px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-1);
}

.selected {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-1);
    margin: 10px;
    text-transform: capitalize;
}

.caret {
    /*color: var(--text-color-1);*/

}
