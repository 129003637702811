.component {
    border: 0;
    margin-bottom: 16px;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}

.input {
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    min-width: 0;
    padding: 0px 14px;
    background: none;
    height: 44px;
    color: var(--text-color-3);
    font-size: 16px;
    font-weight: 400;
}

.input:active, .input:focus {
    outline: none;
}

.input::placeholder {
    color: var(--text-color-3);
}

.input:disabled {
    color: var(--text-color-16);
}

.noDropDownPadding {
    padding-left: 0;
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    background: var(--bg-color-1);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
}

.stakeAmount {
    display: inline-flex;
}

.stakeAmount svg {
    height: 21px;
}

.label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-1);
    margin-bottom: 2px;
}

.dropDownLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: 106px;
    height: 31px;
    background: #4260A6;
    border-radius: 100px;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: var(--text-color-1);
    border: none;
}

.dropDownLabel:active,
.dropDownLabel:focus,
.dropDownLabel:hover,
.dropDownLabel:focus-visible {
    background: #4260A6;
    color: var(--text-color-1);
    border: none;
}