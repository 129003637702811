.titleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titleHeader .headerLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-left: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: var(--text-color-3);
}

.referralMain {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  background-color: var(--bg-color-2);
  border: 1px solid var(--bg-color-4);
  border-radius: 16px;
  padding: 18px;
  min-height: 500px;
}

.header {
  border-bottom: none;
  display: flex;
  align-items: center;
  color: var(--text-color-1);
  justify-content: space-between;
}

.main {
  border-top: none;
  border-bottom: none;
}

.noReferral {
  text-align: center;
  padding: 3rem;
}

.dontHave {
  color: var(--text-color-3);
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

.createReferralBtn {
  margin-top: 52px;
  padding: 8px 15px;
  height: 40px;
  color: var(--text-color-3);
  background: transparent;
  border: 1px solid var(--text-color-3);
  border-radius: 24px;
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
}

.createReferralBtn:active,
.createReferralBtn:hover,
.createReferralBtn:focus,
.createReferralBtn:focus-visible {
  color: white;
  background-color: var(--text-color-3);
  border: none;
}

.createReferralBtn:disabled {
  border: 1px solid var(--text-color-3);
  color: var(--text-color-3);
  background-color: #C9D3FD;
}

.criticalMassStatus {
  color: var(--text-color-3);
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.startPromoting {
  margin-top: 3px;
  color: var(--text-color-16);
  font-size: 16px;
  line-height: 20px;
}

.refreshBtn {
  cursor: pointer;
}

@media (max-width: 450px) {
  .titleHeader .headerLabel {
    font-size: 24px;
  }

  .titleHeader {
    flex-direction: column;
  }

  .noReferral {
    padding-left: 10px;
    padding-right: 10px;
  }
}