.component {
    max-width: 375px;
}

.component :global .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown {
    width: 100%;
    margin: 18px 0 18px;
}

.connectBtn {
    width: 100%;
    height: 48px;
    margin-top: 8px;
    border-radius: 4px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--text-color-10);
}
