.component{

}

.header{
  margin: 30px 0 20px 0 ;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelTitle{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--text-color-1);
}

.reservationContainer {
  display: flex;
  width: 100%;
  margin: 0;
  min-height: 280px;
}

.reservationContainer .reservationCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin: .5rem;*/
  min-height: 9rem;
}

.conteinerTitle{
  margin: 50px 0 20px;
  padding: 0 2rem;
}

.reservationCard .conteinerTitle h2 {
  color: var(--text-color-3);
}

.reservationContainer .line{
  width: 100%;
  height: 0px;
  border: 1px solid var(--bg-color-9);
}

.reservationContainerText{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: .5rem 0 0 1rem;
}

.reservationContainerText .containerText{
  width: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.colorText1{
  width: 100%;
  text-align: center;
  color: var(--text-color-3);
  text-transform: uppercase;
}

.colorText{
  color: var(--text-color-3);
  text-transform: uppercase;
}

.reservationContainerTable{
  position: relative;
  display: flex;
  width: 100%;
  padding: .5rem 0 0 1rem;
  align-items: center;
  justify-content: space-between;
}

.reservationContainerTable .containerCard{
  width: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.reservationContainerTable .containerCard .onlyMobile{
  display: none;
}

.reservationContainerTable .containerCard .coinColumn{
  display: flex;
  align-items: center;
}

.reservationContainerTable .containerCard .cardCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerCard .cardCenter .colorEth{
  margin-left:.5rem ;
  color: var(--text-color-17);
}

.containerCard .lastCard {
  margin-left: 1rem;
}

.reservationContainerTable .colorWhite{
  margin-left:.5rem ;
  color: var(--text-color-1)
}

.reservationContainerTable .colorWhite_{
  color: var(--text-color-1)
}

.reservationContainerTable .bonusText{
  font-size: 11px;
  color: var(--text-color-1)
}

.reservationContainerTable .littleText{
  font-size: smaller;
  color: var(--text-color-1)
}

.progresReservation  :global .ant-progress-inner {
  border-radius: 2px !important;
  border: 1px solid var(--text-color-15);
  background: var(--bg-color-2);
}

.progresReservation :global .ant-progress-text {
  display: none;
}

.progresReservation :global .ant-progress-bg {
  background-color: var(--text-color-15);
  border-radius: 0;
}

.singleIcon svg,
.singleIcon img {
  height: 28px;
  width: 28px;
  margin-right: 7px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.preloader_custom {
  display: flex;
  align-items: center;
  margin: auto;
}

/*RESPONSIVE*/

@media (max-width: 950px) {
  .reservationContainer {
    display: block;
    margin: 0;
  }

  .reservationContainer .reservationCard {
    margin: 0;
  }

  .reservationContainer .line{
    display: none;
  }

  .reservationContainer .reservationContainerText{
    display: none;
  }

  .reservationContainerTable{
    display: block;
    border: 1.5px solid var(--border-color-5);
    margin: .3rem 0;
    border-radius: 5px;
  }

  .reservationContainer .reservationContainerTable .containerCard{
    width: 100%;
    display: block;
    text-align:start;
    margin-bottom: 20px;
  }

  .reservationContainerTable .containerCard .onlyMobile{
    display: block;
    color: var(--text-color-3);
    text-transform: uppercase;
  }

  .reservationContainerTable .containerCard .cardCenter{
    display: flex;
    justify-content:flex-start;
  }

  .conteinerTitle{
    margin: 20px 0 20px;
  }

  .containerCard .lastCard {
    margin-left: 0rem;
  }
}
