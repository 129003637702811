.component {
    min-width: 800px;
}

.component :global .ant-modal-body {
    padding: 15px 16px;
}

.header {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--text-color-3);
}

.note {
    margin-top: 15px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-16);
}

.body {
    display: inline-grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 24px;
    width: 100%;
}

.option {
    position: relative !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
    height: auto;
    padding: 16px;
    background: var(--bg-color-2);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
}

.optionIconsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.optionIcons {
    margin-bottom: 8px;
}

.optionLabel {
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: var(--text-color-1);
}

.optionRewardLabel {
    margin-bottom: 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-16);
}

.optionRewardValue {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--text-color-15);
}

.claimBtnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    flex: 1;
}

.claimBtnWrapper :global .ant-btn,
.claimBtnWrapper :global .ant-btn:disabled {
    background: transparent;
    color: var(--text-color-3);
    padding: 4px 20px;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    border: 1px solid var(--text-color-3);
    border-radius: 100px;
}