.wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 7px 0;
    padding: 0 12px 0 0;
}

.active .activeMark {
    visibility: visible;
}

.active .btn svg {
    color: var(--text-color-20);
    transition: color ease 0.7s !important;
    /*border: 1px solid var(--text-color-20);*/
    /*background-color: var(--text-color-15-20p);*/
    /*border-radius: 4px;*/
}

.active .label {
    color: var(--text-color-20);
}

.activeMark {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 48px;
    background-color: var(--bg-color-3);
    border-radius: 0 4px 4px 0;
    visibility: hidden;
}

.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 48px;
    margin: 0 0 0 5px;
    padding: 9px;
    border: none;
}



.btn:hover{
    background-color: var(--bg-color-14);
    border-radius: 8px;
}

.btn:hover .label,.btn:hover svg{
    transition: color ease 0.7s !important;
    color: var(--text-color-20);
}

.btn:disabled {
    opacity: 0.2;
    pointer-events: none;
}

.btn svg {
    color: var(--text-color-1);
    min-width: 44px;
    min-height: 44px;
    padding: 10px;
    max-height: 44px;
}

.label {
    margin-left: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: var(--text-color-1);
}
