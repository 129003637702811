.additional {
  align-items: stretch;
  margin-top: 2px;
}

.progressBar :global .ant-progress-text {
  display: none;
}

.progressBar :global .ant-progress-show-info .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
}

.progressDesc {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 14px;
  color: var(--text-color-5);
}

.progress :global .ant-progress-bg {
  height: 16px !important;
  border-radius: 0;
}

.progress :global .ant-progress-inner {
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--text-color-15);
  background: transparent;
}

.dropdown {
  position: absolute;
  top: 5px;
  left: 11px;
  width: 115px;
  padding-left: 75px;
}

.card {
  background: var(--bg-color-2);
  border: 2px solid var(--bg-color-4);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  margin-right: 2px;
}

.card:last-child {
  margin-right: 0;
}

.balanceLabel {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  margin-bottom: 5px;
  color: var(--text-color-2);
}

.balanceValue {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color-1);
}