.component {
  min-width: 360px !important;
  width: 750px !important;   
  height: 50%;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 21px;
  line-height: 26px;
  font-weight: 600;
  color: var(--text-color-3);
}

h3, p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-color-16);
}

.card {
  width: 100%;
  display: flex;
}

.tabs {
  margin-top: 0.5rem;
  display: flex;
  align-items: start;
  width: 100%;
}

.tabs  .ant-tabs-nav {
  border: none;
  color: var(--text-color-1);
  height: 48px;
  width: 100%;
  box-shadow: none;
}

.tabs :global .ant-tabs-tab:hover {
  color: var(--text-color-1);
}

.tabs :global .ant-tabs-nav::before {
  border-bottom: none;
}

.tabs :global .ant-tabs-content-holder {
  width: 100%;
}

.tabs :global .ant-tabs-tab:nth-last-child(2) {
  margin-right: 0;
}

.tab {
  text-decoration: underline;
}

.ant-tabs-content-holder {
  width: 100% !important;
}

.alignIconToggle {
  margin-right: 1rem;
}

.sorting {
  display: flex;
  width: 100%;
  justify-content: center;
}

.btnCopy {
  background: transparent!important;
  font-size: 15px;
  line-height: 18px;
  color: var(--text-color-3);
  width: 190px;
  height: 31px;
  border: 2px solid var(--border-color-6);
  border-radius: 100px;
}

.loading {
  justify-content: center;
  display: flex !important;
  margin-top: 0.8rem;
}




