.component {
    /*background-color: var(--bg-color-15);*/
    /*padding: 10px;*/
    /* box-shadow: 0 0 0.5px var(--shadow-color-1); */
    border-radius: 8px;
    /*border: 1px solid var(--bg-color-4);*/
    /*margin-bottom: 32px;*/
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px;
    /*padding: 12px 24px;*/
    /*border-bottom: 1px solid var(--border-color-1);*/
}

.headerLabel, .filterDropDown {
    height: 48px;
    display: flex;
    flex-direction: row;
    background-color: var(--bg-color-15);
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
}
.filterDropDown:hover,.filterDropDown:focus{
    background-color: var(--bg-color-15);
}

.legends{
    display: flex;
    align-items: center;
    gap:20px
}
.legendsStackedLabel, .legendsUnstackedLabel{
    width: 15px;
    height: 15px;
    border-radius: 4px;
}
.legendsItem{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:10px
}

.legendsStackedLabel{
    background: #E53E4C;
    box-shadow: 0px 0px 8px #E53E4C;
}

.legendsUnstackedLabel{
    background: #1286E3;
    box-shadow: 0px 0px 8px #1286E3;
}

.legendsName{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: var(--text-color-1);
}

.overflowContainer {
    max-height: 400px;
    overflow: auto;
}

.iconSet {
    margin-right: 8px;
}
.filters{
    display: flex;
    align-items: center;
    gap:20px;
    justify-content: flex-end;
    margin-left: auto;
}
.filters .filtersItem{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: flex-end;
}

.dropDownLabel {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: var(--text-color-21);
}

/*.filters > button, .filters > button:hover, .filters > button:active, .filters > button:focus {*/
/*    margin-left: 8px;*/
/*    background-color: var(--bg-color-4);*/
/*    border-radius: 24px;*/
/*    border: none;*/
/*}*/

/*.filters > button:nth-child(2) {*/
/*    margin-right: 34px;*/
/*}*/

.chart {
    background-color: var(--bg-color-15);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    /*padding: 24px;*/
}

.emptyBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emptyLabel {
    max-width: 200px;
    margin-top: 24px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--text-color-2);
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .headerLabel {
        padding: 16px;
    }

    .filters {
        padding-bottom: 10px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-start;*/
        /*width: 100%;*/
    }

    .filterDropDown {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filters button {
        margin-left: 0;
    }

    .filters button + button {
        margin-top: 16px;
    }
}

@media (max-width: 660px) {
    .filters {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
}
