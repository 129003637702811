.dashboardView p, h1, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

.titleRow{
  display: flex;
  width: 100%;
  overflow-y: auto;
}

.title{
  color: var(--text-color-3);
  font-family: Mulish;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.quickMenuWrapper{
  width: 50%;
}

.chartWrapper {
  min-width: 1000px;
}

/*RESPONSIVE*/