@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Mulish;
  src: url('resources/fonts/Mulish-Regular.ttf');
}

@font-face {
  font-family: Mulish;
  font-weight: 600;
  src: url('resources/fonts/Mulish-SemiBold.ttf');
}

@font-face {
  font-family: Mulish;
  font-weight: 700;
  src: url('resources/fonts/Mulish-Bold.ttf');
}

body {
  margin: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Mulish';
}

* {
  box-sizing: border-box;
  transition: background-color 0.5s ease, color 0.5s ease;
}

svg,
svg * {
  transition: none !important;
}

p,
span {
  font-family: Mulish;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 21px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

button {
  font-family: Mulish;
}

#root {
  min-height: 100vh;
}

[text-elipsis] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-modal-content {
  background-color: var(--bg-color-1);
  border-radius: 16px;
  border: 2px solid var(--bg-color-4);
}

.ant-modal-body {
  border: none;
}

.ant-modal .ant-modal-footer:empty {
  display: none;
}

.ant-modal .ant-modal-close {
  color: var(--text-color-4);
}

.ant-modal-close-x {
  color: var(--text-color-1);
  font-size: 12px;
}

.ant-notification-notice {
  word-break: break-word;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-popover-inner {
  background: transparent;
}

.ant-popover-arrow {
  display: none;
}

.ant-dropdown-menu {
  background-color: var(--bg-color-1);
  padding: 0;
  max-height: 250px;
  overflow: auto;
}

.ant-dropdown-menu-item {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color-1);
  text-transform: capitalize;
}

.ant-dropdown-menu-item:hover {
  background-color: var(--bg-color-16);
  color: var(--text-color-1);
}

.ant-dropdown-menu-item-selected {
  background-color: var(--bg-color-16);
  /*color: var(--text-color-3);*/
}

.ant-tabs-tab {
  color: var(--text-color-1);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-color-15);
}

.ant-tabs-ink-bar {
  background-color: var(--text-color-15);
}

.ant-input,
.ant-input:hover,
.ant-input:active,
.ant-input:focus {
  background: var(--bg-color-1);
  border: 2px solid var(--bg-color-4);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--text-color-1);
  font-size: 16px;
  line-height: 20px;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 2px var(--bg-color-4);
}

.ant-dropdown-trigger,
.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:active,
.ant-dropdown-trigger:focus {
  /*border-radius: 100px;*/
  /*background-color: var(--bg-color-15);*/
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: var(--text-color-1);
  border: none;
  align-items: center;
  text-decoration: none !important;
}

.ant-dropdown-trigger svg,
.ant-dropdown-trigger:hover svg,
.ant-dropdown-trigger:active svg,
.ant-dropdown-trigger:focus svg {
  /*width: 11px;*/
  /*top: 2px;*/
  /*position: relative;*/
}

.ant-switch {
  background-color: var(--border-color-10);
}

.ant-switch-checked {
  background-color: var(--border-color-10);
}

.ant-switch-handle::before {
  background-color: var(--bg-color-2);
}

.ant-switch-checked .ant-switch-handle::before {
  background-color: #ffffff;
}

.ant-switch-checked .ant-switch-handle:hover::before {
  background-color: #ffffff;
  opacity: 0.4;
  border-radius: 100px;
}

.ant-tooltip-inner {
  background-color: rgba(90, 138, 252, 0.95);
  color: var(--text-color-1);
}

.ant-tooltip-arrow-content {
  background-color: rgba(90, 138, 252, 0.95);
}

.ant-pagination-item {
  background-color: transparent;
  border: none;
}

.ant-pagination-item a {
  color: var(--text-color-16);
  text-decoration: none !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent;
  border: 0px;
  color: var(--text-color-16);
}

.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--text-color-1) !important;
}

.theme-default .apexcharts-legend-text {
  color: var(--text-color-2) !important;
}

a:hover {
  color: var(--text-color-15);
}

::selection {
  color: #fff;
  background: var(--text-color-12);
}

:root {
  --bg-color-1: #f6f8fa;
  --bg-color-2: #ffffff;
  --bg-color-3: #ff4339;
  --bg-color-4: #ebeef8;
  --bg-color-4-1: rgba(90, 138, 252, 0.4);
  --bg-color-5: #f6f8fe;
  --bg-color-6: rgba(170, 175, 179, 0.08);
  --bg-color-7: #ffffff;
  --bg-color-8: #eef769;
  --bg-color-9: #4260a633; /*  check for the light theme*/
  --bg-color-10: #00d26d; /*  check for the light theme*/
  --bg-color-13: #fd8f77;
  --bg-color-14: #e4ecff;
  --bg-color-15: rgba(255, 255, 255, 0.05);
  --bg-color-16: rgba(255, 255, 255, 0.03);

  --text-color-1: #060a0d;
  --text-color-1-1: #4260a6;
  --text-color-2: #717980;
  --text-color-3: #5a8afc;
  --text-color-4: #aaafb3;
  --text-color-5: #000;
  --text-color-6: #4f6ae6;
  --text-color-7: #95a6ef;
  --text-color-8: #eef769;
  --text-color-9: #7288ea;
  --text-color-10: #fff;
  --text-color-11: #1bc943;
  --text-color-12: #f4772e;
  --text-color-13: #29abb5;
  --text-color-14: #39c399;
  --text-color-15: #ac344e;
  --text-color-15-20p: rgba(172, 52, 78, 0.2);
  --text-color-16: #7f7f94;
  --text-color-17: #fd8f77; /** check for the light theme*/
  --text-color-18: #00d26d; /** check for the light theme*/
  --text-color-20: #1286E3;
  --text-color-21: #23252b;
  --text-color-22: #E53E4C;

  --border-color-1: #e3e4e6;
  --border-color-2: #e3e4e6;
  --border-color-3: #60626826;
  --border-color-4: #6062680f;
  --border-color-5: #4260a6; /* check the color for light theme*/
  --border-color-6: rgba(66, 96, 166, 0.2); /* check the color for light theme*/
  --border-color-9: #353945;
  --border-color-10: rgba(255,255,255,0.1);


  --shadow-color-1: rgba(6, 10, 13, 0.4), 0 8px 16px rgba(113, 121, 128, 0.08);
}

.theme-dark {
  --bg-color-1: #0B1117;
  --bg-color-2: #2b344b;
  --bg-color-3: #fff;
  --bg-color-4: #344574;
  --bg-color-4-1: #344574;
  --bg-color-5: rgba(79, 106, 230, 0.08);
  --bg-color-6: rgba(147, 148, 150, 0.08);
  --bg-color-7: #040b12;
  --bg-color-8: #fd8f77;
  --bg-color-9: #4260a633;
  --bg-color-10: #00d26d;
  --bg-color-11: #4260a6;
  --bg-color-13: #eef769;
  --bg-color-14: #161921;
  --bg-color-15: rgba(255, 255, 255, 0.05);
  --bg-color-16: rgba(255, 255, 255, 0.03);

  --text-color-1: #ffffff;
  --text-color-1-1: #ffffff;
  --text-color-2: #939496;
  --text-color-3: #5a8afc;
  --text-color-4: #606268;
  --text-color-5: #fff;
  --text-color-6: #7288ea;
  --text-color-7: #4f6ae5;
  --text-color-8: #95a6ef;
  --text-color-10: #fff;
  --text-color-11: #1bc943;
  --text-color-12: #f4772e;
  --text-color-13: #29abb5;
  --text-color-14: #39c399;
  --text-color-15: #eef769;
  --text-color-15-20p: rgba(238, 247, 105, 0.2);
  --text-color-16: #7f7f94;
  --text-color-17: #fd8f77;
  --text-color-18: #00d26d;
  --text-color-19: #c2c6cd;
  --text-color-20: #1286E3;
  --text-color-21: #5E6272;
  --text-color-22: #E53E4C;

  --border-color-1: #44494d;
  --border-color-2: #ff4339;
  --border-color-3: #60626887;
  --border-color-4: #6062681f;
  --border-color-5: #4260a6;
  --border-color-6: #5a8afc;
  --border-color-7: #c2c6cd;
  --border-color-8: rgba(33,39,53,0.4);
  --border-color-9: #353945;
  --border-color-10: rgba(255,255,255,0.1);

  --shadow-color-1: rgba(255, 255, 255, 0.4), 0 8px 16px rgba(0, 0, 0, 0.08);
}
