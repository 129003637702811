.component {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    /*width: 100%;*/
    /*height: 72px;*/
    /*padding: 24px 18px;*/
    /*background-color: var(--bg-color-2);*/
    /*box-shadow: 0 0 0.5px rgba(6, 10, 13, 0.4);*/
}

.logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 24px;
    z-index: 3;
    color: var(--text-color-1);
    font-size: 18px;
    font-weight: bold;
}

.logo svg {
    height: 24px;
    color: var(--text-color-1);
    margin-right: 12px;
}

.title{
    font-size: 18px;
    font-weight: bold;
}

.themeToggle {
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0 24px 0;
    color: var(--text-color-2);
    white-space: nowrap;
}

.themeToggle svg {
    min-width: 24px;
    min-height: 24px;
    margin: 0 12px 0 0;
}

.innerLogo {
    position: absolute;
    top: 24px;
    left: 10px;
    width: 100%;
    height: 24px;
    padding-right: 24px;
    z-index: 3;
    color: var(--text-color-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerLogo svg {
    margin-right: 12px;
}

.menuBtn {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    z-index: 1001;
}

.burger {
    width: 24px;
    height: 24px;
}

.burger :global .burger-lines,
.burger :global .burger-lines:after,
.burger :global .burger-lines:before {
    left: 0 !important;
    background-color: var(--text-color-4);
}

.burger :global .burger-lines:before {
    top: 0.6em;
}

.burger :global .burger-lines:after {
    top: -0.6em;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    visibility: hidden;
}

.maskOpen {
    width: 100%;
    visibility: visible;
}

.menuList {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100vh;
    visibility: hidden;
    overflow: hidden;
    z-index: 1000;
    padding: 90px 0 24px;
    background-color: black;
    transition: all 0.4s ease;
}

.menuListOpen {
    width: 275px;
    visibility: visible;
}

/* RESPONSIVE */
@media (max-width: 950px) {
    .logo svg {
        margin-right: 10px;
        width: auto;
    }

    .innerLogo svg {
        width: auto;
    }
}

@media (min-width: 769px) {
    .component {
        display: none;
    }
}
