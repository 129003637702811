.titleRefferals {
  color: var(--text-color-3);
  margin: 0;
  padding: 0 2rem;
}

.header{
  margin: 30px 0 20px 0 ;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:20px;
  width: 100%;
}

.labelTitle{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--text-color-1);
}

.personalInfoContainer {
  display: flex;
  width: 100%;
}

.personalInfoContainer .personalCard {
  width: 100%;
  margin: .5rem;
}

.personalCard .percentReferral {
  display: flex;
}

.personalCard .percentReferral .circle{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--text-color-3);
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-1);
}

.personalCard .percentReferral .circleText{
  margin-left: .4rem;
}

.listMenu {
  color: var(--text-color-1);
  background-color: var(--bg-color-2);
  border: 2px solid var(--bg-color-4);
  position: relative;
  right: 0;
  padding: .5rem;
}

.circleText .text1{
  color: var(--text-color-2);
  text-transform: uppercase;
  margin: 0;
}

.circleText .text2{
  color: var(--text-color-3);
  font-weight: bold;
}

.personalCard .referralIcons{
  display: flex;
  justify-content: flex-end;
}

.personalCard .referralIcons svg {
  cursor: pointer;
  color: var(--text-color-1);
  margin-right: .5rem;
}

.referralIcons :global .ant-dropdown-trigger {
  background-color: unset;
}

.listMenu :global .ant-dropdown-menu-item::before {
  margin-right: .3rem;
  padding: 0;
  content: url('../../../../resources/svg/ellipse-list.png');
}

.listMenu :global .ant-dropdown-menu-item:hover {
  color: inherit;
}

.listMenu span {
  margin-left: .5rem;
}

.listMenu h3 {
  color: var(--text-color-1);
}

.personalCard .swappAnnouncements{
  margin: 0.5rem auto;
  text-align: center;
  width: 100%;
}

.personalCard .swappAnnouncements .rederrerStatus{
  color: var(--text-color-3)
}

.personalCard .swappAnnouncements .startPromoting{
  color: var(--text-color-2);
  display: block;
  padding-bottom: 2rem;
}

.swappAnnouncements {
  text-align: center;
}

.swappAnnouncements .btnSwapp {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  background-color: transparent;
  color: var(--text-color-10);
  border: 2px solid var(--text-color-2);
  box-sizing: border-box;
  border-radius: 16px;
  align-items: center;
  display: flex;
  align-self: center;
  margin: 20px auto;
}
.swappAnnouncements .btnSwapp:hover {
  border: 2px solid var(--text-color-10);
}


.tableHeader {
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  top: 0px;
  padding-bottom: 10px;
  flex: 1;
  margin-top: 15px;
}

.linkWrapper a {
  color: var(--text-color-15) !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline !important;
}

.tableHeader div span {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.iconSet {
  justify-content: inherit;
  transform: scale(0.7);
  margin-left: -35px;
}

.cellBlock {
  display: flex;
  align-items: center;
}

.singleIcon {
  margin-right: 7px;
}

.singleIcon svg,
.singleIcon img {
  height: 28px;
  width: 28px;
  margin-right: 7px;
}

.tableHeader div p {
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: var(--text-color-3);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.tableRow {
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  flex: 0 1 100%;
}

.tableRow div {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.tableWrapper {
  width: 100%;
  min-height: 500px;
  padding-bottom: 70px;
  position: relative;
  /*margin: .5rem;*/
}

.infoBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleBlock {
  display: flex;
  align-items: center;
}

/*RESPONSIVE*/

@media (max-width: 1024px) {
  .tableHeader,
  .tableRow {
    grid-template-columns: 2fr 1fr 2fr 2fr 2fr;
    grid-gap: 10px;
  }
}

@media (max-width: 950px) {
  .personalInfoContainer .personalCard {
    width: 100%;
    margin: 0;
  }

  .personalCard .swappAnnouncements{
    margin: 5rem auto;
  }

  .titleRefferals {
    padding: 0 1rem;
  }

  .iconSet {
    margin-left: -15px;
  }
}

@media (max-width: 767px) {
  .tableWrapper {
    min-width: 750px;
  }

  .personalInfoContainer {
    overflow-x: auto;
  }

  .tableHeader div p {
    font-size: 13px;
    line-height: 17px;
  }
}
