.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.headerLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    color: var(--text-color-3);
    height: 55px;
}

.chooseReservation {
    font-size: 12px;
    color: var(--text-color-2);
    font-weight: 400;
}

.main {
    position: relative;
    margin-top: 56px;
    width: 96%;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
}

.header {
    height: 250px;
    background-color: var(--bg-color-7);
}

.body {
    background-color: white;
}

.proposalTitle {
    margin-top: 50px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.863);
    font-size: 24px;
    font-weight: bold;
}

.proposalDate {
    margin-top: 15px;
    margin-left: 10px;
    color: #ffffffad;
    font-size: 12px;
}

.passed {
    color: #00D26D;
    border: 1px solid #00D26D;
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: bold;
    margin-right: 10px;
}

.board {
    position: absolute;
    top: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row1 {
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.for {
    background-color: white;
    padding: 24px;
    font-weight: bold;
}

.forProgressRow1 {
    color: black;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
}

.address {
    color: rgba(0, 0, 0, 0.39);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.178);
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.178);
}

.info:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.178);
}
