.component {
    opacity: 0;
    /*transition: none;*/
    white-space: nowrap;
    color: var(--text-color-1);
    transition: transform ease 0.7s !important;
}

.component:hover {
    /*text-decoration: underline;*/
}

.visible {
    color: var(--text-color-1);
    opacity: 1;
    transition: 0.5s;
}
