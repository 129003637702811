.component {
    /* background-color: var(--bg-color-2); */
    /* box-shadow: 0 0 0.5px var(--shadow-color-1); */
    background: var(--bg-color-15);
    border-radius: 8px;
    font-family: Poppins;
}

.row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 74px;
    padding: 20px;
    background-color: var(--bg-color-16);
    border-radius: 8px 8px 0px 0px;
}

.pool_avatars {
    margin-right: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.pool_info {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.row1 .pool_label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-color-1);
    width: 100%;
}
.pool_epoch {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: var(--text-color-20);
}

.pool_epoch_countdown {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: lowercase;
    color: var(--text-color-1);
}

.apy_body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 6px;
    min-width: 72px;
    border: 1px solid var(--text-color-21);
    border-radius: 72px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
}

.apy_body .apyTitle{
    color: var(--text-color-21);
}

.apy_body .apyValue{
    font-weight: 600;
    color: var(--text-color-1);
}

.pool_apy{
    align-self: flex-start;
    display: flex;
    gap: 5px;
}

.row2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 66px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--bg-color-15);
}
.reward{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
}
.reward .row_label{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--text-color-21);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.row_value{
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-color-1);
}
.swappIcon svg{
    width: 13px;
    height: 13px;
}

.stakingBtn, .stakingBtn:hover, .stakingBtn:active, .stakingBtn:focus {
    /*margin: 0 0 0 auto;*/
    border-radius: 8px;
    border: none;
    background-color: var(--text-color-20);
    min-width: 83px;
    min-height: 32px;
}
.stakingBtn span{
    font-family: Poppins;
    color: var(--text-color-1);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
}

.balance{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.balance .row_value_2{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--text-color-21);
}

.pool_stak_bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: var(--bg-color-4);
}

.pool_stak_bar div {
    height: 4px;
}

.pool_stak_bar div:hover {
    height: 8px;
    transform: translateY(-2px);
}

.line0{
    box-shadow: 0 0 6px 1px #1286E3;
}
.line1{
    box-shadow: 0 0 6px 1px #0FCB7C;
}
.line2{
    box-shadow: 0 0 6px 1px #F0AD00;
}

.pool_stak_bar div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

}

.pool_stak_bar div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pool_stak_bar div:hover {
    border-radius: 4px;
}









.row2 .col1 {
    padding: 24px;
}

.row2 .col2 {
    padding: 24px;
}

.row3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: 3px;
}

.row4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-top: 3px;
    position: relative;
}

.row5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.row6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: var(--text-color-1);
    text-align: end;
}

.row6 .pool_label {
    justify-content: flex-end;
}

.row6 .pool_label {
    font-size: 14px;
}

/*.row_label {*/
/*    !*margin-bottom: 12px;*!*/
/*    !*font-style: normal;*!*/
/*    !*font-weight: 400;*!*/
/*    !*font-size: 16px;*!*/
/*    !*line-height: 16px;*!*/
/*    !*letter-spacing: 0.04em;*!*/
/*    !*color: var(--text-color-16);*!*/
/*    !*display: flex;*!*/
/*    !*justify-content: space-between;*!*/
/*    !*align-items: center;*!*/
/*    !*width: 100%;*!*/
/*}*/

.row2 .row_value, .row5 .row_value {
    margin-bottom: 0;
}

/*.row_value_2 {*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*    line-height: 24px;*/
/*    color: var(--text-color-5);*/
/*    margin-bottom: 20px;*/
/*}*/

.balance_tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
}

.balance_tooltip svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.balance_tooltip > span:nth-child(2) {
    margin-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.balance_tooltip > span:nth-child(3) {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.pool_epoch svg {
    cursor: pointer;
}

.coinWrapper {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    padding: 0 5px !important;
}

.coming {
    color: var(--text-color-1);
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .row1,
    .row3,
    .row4 {
        padding: 16px;
    }

    .row2 .col1,
    .row2 .col2 {
        padding: 16px;
    }

    .pool_label {
        font-size: 14px;
    }
}
