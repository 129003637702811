.tooltip {
    max-width: 360px;
}

.tooltip :global .ant-tooltip-inner {
    padding: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.08px;
    color: var(--text-color-1);
    background-color: var(--bg-color-1);
    border-radius: 4px;
}

.tooltip :global .ant-tooltip-arrow-content {
    background-color: var(--bg-color-1);
}


.icon {
    flex: none;
    display: inline-block;
    width: 14px;
    height: 14px;
    /*margin-left: 8px;*/
    color: var(--text-color-1);
    line-height: 16px;
}

.icon svg {
    width: 100%;
    height: 100%;
}
