.component {
}

.header{
    margin: 30px 0 20px 0 ;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labelPools , .labelOverview{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--text-color-1);
}

.labelOverview {
    margin: 30px 0 20px 0;
}

.viewSelect{
    display: flex;
    align-items: center;
    gap: 10px;
}

.viewSelect > .icon{
    cursor: pointer;
    padding: 6px 8px;
    background: var(--bg-color-15);
    border-radius: 4px;
}

.viewSelect > .icon:hover{
    opacity: 0.7;
}

.active svg rect{
    fill: var(--text-color-20);

}
.viewSelect > .icon.active{
    opacity: 1;
}


/*.labelOverview {*/
/*    margin-bottom: 32px;*/
/*    font-family: Mulish;*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*    line-height: 24px;*/
/*    color: var(--text-color-3);*/
/*}*/

.cards {
    display: inline-grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 32px;
    width: 100%;
    /*margin-bottom: 32px;*/
}

.divider {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: var(--bg-color-2);
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .labelPools {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 32px;
    }

    /*.labelOverview {*/
    /*    margin-bottom: 16px;*/
    /*    font-size: 14px;*/
    /*}*/

    .cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (max-width: 1000px) {
    .header{
        margin: 15px 0 10px 0 ;
    }

    .viewSelect{
        display: none;
    }
    .cards {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

