.header{
    padding: 0;
    position: sticky;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 80px;
    background-color: var(--bg-color-7);
    border-bottom: 1px solid var(--border-color-8);
    display: flex;
    align-items: center;
}

.logo{
    display: flex;
    align-items: center;
    padding-left: 30px;
    flex-basis: 273px;
    flex-shrink: 0;
}

.border{
    height: 24px;
    width: 2px;
    background-color: #353945;
    margin-left: auto;
}

.brandName{
    margin-left: 10px;
    font-weight: bold;
    color: var(--text-color-10);
    letter-spacing: 1px;
    font-size: 18px;
}

.container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 20px;
}

@media (max-width: 768px) {
    .logo {
        display: none;
    }
    .container{
        padding: 0 10px 0 0;
    }
}

