.component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.component svg {
    width: 32px;
    height: 32px;
}

.component svg:not(:first-child) {
    margin-left: -15px;
}
