/*.personalInfoContainer {*/
/*  display: block;*/
/*  width: 100%;*/
/*}*/

.personalInfoContainer{
  display: inline-grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 32px;
  width: 100%;
}

.personalInfoContainer .personalCard h3 {
  color: var(--text-color-3);
  font-weight: 600;
  margin-bottom: 40px;
}

.personalInfoContainer .personalCard span {
  color: var(--text-color-15);
  font-size: 1.5rem;
  width: 100%;
}

.personalInfoContainer .personalCard .gtArrow {
  position: relative;
  cursor: pointer;
  left: 98%;
}

.personalInfoContainer .personalTimeLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: .5rem 0;
  background-color: var(--bg-color-4);
}

.personalTimeLeft p {
  margin: 1rem 0;
}

.personalTimeLeft h3{
  color: var(--text-color-3);
}

.personalTimeLeft .counterTime {
  font-size: x-large;
  font-weight: 700;
  color: var(  --text-color-15);
}

.harvestBtn{
  background-color: var(--bg-color-4);
  color: var(  --text-color-3);
  border: 2px solid var(--border-color-5);
  box-sizing: border-box;
  border-radius: 16px;
}

.linkBtn{
  padding: 7px 12px;
  background: var(--bg-color-14);
  border-radius: 4px;
  cursor: pointer;
}
.linkBtn a{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-transform: capitalize;
  color: var(--text-color-20);
  text-decoration: none!important;
}

.personalInfoContainer .personalCard .secondaryInfo {
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
}

.personalInfoContainer .personalCard .greyInfo {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: var(  --text-color-16);
}

/*RESPONSIVE*/

@media (min-width: 1002px) {
  /*.personalInfoContainer{*/
  /*  display: flex;*/
  /*  width: 100%;*/
  /*}*/

  .personalInfoContainer .personalCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    margin: .5rem;
  }

  .personalInfoContainer .personalTimeLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background-color: var(--bg-color-4);
    margin: .5rem ;
  }
}

@media (max-width: 950px) {
  .personalInfoContainer .personalCard h3 {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .personalInfoContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 6px;
  }
}
