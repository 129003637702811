.container{
  font-family: Poppins;
  color: var(--text-color-1);
  margin-bottom: 30px;
}

.header{
  min-height: 98px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header .title{
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
}

.header .supplyBox{
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.header .supplyBox .supplyItem{
  display: flex;
  align-items: center;
}
.header .supplyBox .supplyItem .supplyTitle{
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: var(--text-color-21);
}

.header .supplyBox .supplyItem .supplyAmount{
  font-weight: 800;
  font-size: 14px;
}

.supplyIcon{
  display: flex;
  align-items: center;
}

.supplyIcon svg{
  width: 13px;
  height: 13px;
  margin-right: 4px;
  margin-left: 10px;
}

.balanceContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--bg-color-15);
  border-radius: 8px;
  padding: 20px;
}

.balanceTitle{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.balanceList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.balanceItem{
  border-right: 2px solid var(--border-color-9);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  flex-grow: 1;
  flex-wrap: wrap;
}

.balanceItem:last-child{
  border-right: none;
  flex-grow: 0.7;
}

.balanceIcon{
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 20px;
}

.walletIcon{
  background: rgba(18, 134, 227, 0.1);
}

.rewardIcon{
  background: rgba(15, 203, 124, 0.1);
}
.calendarIcon{
  background: rgba(240, 173, 0, 0.1);
}

.balanceBody{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.balanceItemTitle{
  color: var(--text-color-21);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.balanceAmount{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.balanceAmount .supplyIcon svg{
  margin-left: 0;
}

.balanceBtn{
  margin-left: auto;
  width: 118px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.claimBtn{
  background: #0FCB7C;
}

.receiveBtn{
  background: #1286E3;
}


@media (max-width: 1024px) {
  .balanceList{
    flex-direction: column;
    gap: 10px;
  }
  .balanceItem{
    border-right: none;
    border-bottom: 2px solid var(--border-color-9);
    padding-bottom: 10px;
    width: 100%;
  }
  .balanceItem:last-child{
    border-bottom: none;
    padding-bottom: 0;
  }
}


@media (max-width: 600px) {
  .balanceBtn{
    width: 80px;
    height: 30px;
  }
}
