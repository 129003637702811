.component {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);
    margin-left: 1px;
    background-color: var(--bg-color-15);
    box-shadow: 0 8px 16px rgba(113, 121, 128, 0.08), 0 0 0.5px rgba(6, 10, 13, 0.4);
    border-radius: 16px;
    border: none;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    /*border: 2px solid var(--border-color-6);*/
    height: 74px;
    align-items: center;
    cursor: pointer;
}

.col {
    height: 44px;
    align-items: center;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.col:nth-child(1) {
    display: flex;
    flex-direction: row;
}

.label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    /* text-transform: uppercase; */
    color: var(--text-color-2);
    margin-right: 9px;
}

.tokenEnablelabel {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: var(--text-color-2);
    margin-left: 9px;
}

.tokenEnableColor {
    color: var(--text-color-15);
}

.value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-15);
}

.logo svg {
    width: 24px;
    height: 24px;
}

.name {
    margin-left: 16px;
}

.arrow {
    width: 40px;
    height: 40px;
    background-color: var(--bg-color-1);
    border-color: var(--border-color-1);
    border-radius: 4px;
}

.arrow:hover {
    background-color: var(--bg-color-6);
    border: 1px solid var(--bg-color-6);
}

.body {
    margin-top: 2px;
    overflow: hidden;
    max-height: 100%;
    border-radius: 0 0 4px 4px;
    transition: transform 0.3s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
}

.body.collapsed {
    margin-top: 0;
    height: 0;
    padding: 0;
    transform: scaleY(0);
}

.row1 {
    height: 92px;
    border-bottom: 1px solid var(--border-color-1);
}

.row1 {
    display: inline-flex;
    grid-gap: 64px;
    grid-template-columns: minmax(0, 1fr) auto;
}

.col1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.col2 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: minmax(0, 356px) minmax(0, 356px);
}

.balanceLabel {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    margin-bottom: 12px;
    color: var(--text-color-2);
}

.balanceValue {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-1);
}

.apy {
    font-size: 14px;
}

.inputRow {
    flex-flow: row;
    align-items: inherit;
}

.inputRow :global .ant-col {
    margin-top: 2px;
}

.inputLabel {
    margin-bottom: 8px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color-2);
}

.inputValue :global .ant-input {
    border-right: none;
}

input::placeholder { /* Chrome/Opera/Safari */
    color: var(--text-color-3) !important;
}

.inputMaxBtn,
.inputMaxBtn:hover,
.inputMaxBtn:focus {
    width: 100px;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: var(--text-color-3);
    background: transparent;
    border: 2px solid #344574;
    box-sizing: border-box;
    border-radius: 16px;
}

.addonWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #4260A6;
    border-radius: 100px;
    padding: 4px 8px 4px 4px;
}

.addonWrap.withSelect {
    padding-right: 35px;
}

.addonIcon {
    height: 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.addonIcon svg {
    width: 24px;
    height: 24px;
}

.addonLabel {
    margin-left: 8px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color-1);
}

.amountSlider {
    margin: 24px 0;
}

.gasGroup {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-row: 2;
    grid-gap: 16px;
}

.gasOption {
    height: 92px;
    padding: 24px 32px;
    background-color: var(--bg-color-1);
    border: 1px solid var(--border-color-1);
    box-sizing: border-box;
    border-radius: 4px;
}

.gasOption>span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.gasOption:global(.ant-radio-button-wrapper:not(:first-child)::before) {
    display: none;
}

.gasOption:global(.ant-radio-button-wrapper) {
    border-left-color: var(--border-color-1) !important;
}

.gasOption:global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    border: 1px solid var(--border-color-2) !important;
}

.gasOption:global(.ant-radio-button-wrapper-checked) {
    background-color: var(--bg-color-1);
}

.gasOption:global(.ant-radio-button-wrapper-checked) .gasOptionRadio {
    border: 8px solid var(--border-color-2);
}

.gasOptionName {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--text-color-1);
}

.gasOptionValue {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-1);
}

.gasOptionRadio {
    width: 24px;
    height: 24px;
    border: 2px solid var(--border-color-1);
    border-radius: 50%;
}

.gasOptionRadio :global .ant-radio-inner {
    width: 24px;
    height: 24px;
    border: 2px solid var(--border-color-1);
}

.actionRow {
    padding: 8px 24px 24px;
}

.actionBtn {
    background: var(--text-color-15);
    border-radius: 100px;
    color: var(--bg-color-4);
    border: none;
    box-shadow: none;
    min-width: 120px;
    font-size: 15px;
    font-weight: 600;
}

.actionBtn:disabled,
.actionBtn:disabled:hover {
    background: var(--text-color-15);
    color: var(--bg-color-4);
}

.actionBtn:hover,
.actionBtn:focus {
    background: var(--text-color-15);
    color: var(--bg-color-4);
}

.innerCol1,
.innerCol2 {
    flex: 0 0 calc(50% - 1px);
}

.walletBlnc {
    /* display: none; */
}

.card {
    background: var(--bg-color-2);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px;
    margin-right: 2px;
}

.card:last-child {
    margin-right: 0;
}

.actionBtnWrapper {
    margin-top: 12px;
    margin-bottom: 12px;
    background: rgba(238, 247, 105, 0.1);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    position: relative;
}

.withdrawWrapper {
    background: rgba(253, 143, 119, 0.1);
    flex-wrap: wrap;
}

.withdrawBtn,
.withdrawBtn:hover,
.withdrawBtn:active,
.withdrawBtn:focus {
    background-color: #FD8F77;
}

.withdrawBtn:disabled,
.withdrawBtn:disabled:hover,
.withdrawBtn:disabled:active,
.withdrawBtn:disabled:focus {
    background-color: var(--bg-color-6);
}

.inputWrapper {
    margin-top: 16px;
}

.filters>button,
.filters>button:hover,
.filters>button:active,
.filters>button:focus,
.filters>button:disabled,
.filters>button:disabled:hover {
    width: 100%;
    margin-left: 0 !important;
}

.striked {
    margin-bottom: 3px;
}

.warning {
    color: var(--text-color-15);
    font-weight: bold;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.dropdown {
    position: absolute;
    top: 5px;
    left: 11px;
    width: 115px;
    padding-left: 75px;
}

.potentialBonus {
    display: flex;
    flex-wrap: wrap;
}

.potentialBonus span {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-1);
    margin-bottom: 2px;
    display: flex;
    align-items: center;
}

.bonusLabel {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.infoList {
    padding-inline-start: 20px;
}

.potentialBonus .card {
    padding: 12px;
}

.balanceLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.balanceLabel .tooltip {
    margin-left: auto;
    width: auto;
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .header {
        grid-template-columns: minmax(0, 4fr) minmax(0, 1fr) minmax(0, 40px);
    }

    .col:nth-child(2) {
        display: none;
    }

    .inputRow {
        flex-flow: column;
    }

    .innerCol1,
    .innerCol2 {
        flex: 0 0 100%;
    }

    .gasOption {
        padding: 24px 16px;
    }

    .walletBlnc {
        display: block;
    }

    .balanceRow :global .ant-col {
        border-left: none !important;
    }

    .card {
        margin: 0 3px 3px 0;
    }
}

@media (max-width: 450px) {
    .addonLabel {
        display: none;
    }

    .header {
        grid-template-columns: minmax(0, 16fr) minmax(0, 8fr);
    }

    .header {
        padding-left: 15px;
        padding-right: 15px;
    }

    .name {
        margin-left: 8px;
    }
}
