.component {
    display: inline-grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 32px;
    width: 100%;
}

.link {
    font-weight: 600;
    color: var(--text-color-15);
}

.link:hover {
    color: var(--text-color-9);
}

.linkBtn{
    padding: 7px 12px;
    background: var(--bg-color-14);
    border-radius: 4px;
    cursor: pointer;
}
.linkBtn a{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    text-transform: capitalize;
    color: var(--text-color-20);
    text-decoration: none!important;
}

/* RESPONSIVE */
@media (max-width: 1024px) {
    .component {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 6px;
    }
}
