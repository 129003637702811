.gasPrice {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.04em;
	margin-top: 15px;
	color: var(--text-color-5);
	text-align: center;
}

.separator {
	color: var(--text-color-3);
}

.warning {
	color: var(--bg-color-8);
}

.gasLink,
.gasLink:hover {
	text-decoration: none !important;
	color: inherit;
}

@media (max-width: 400px) {
	.separator {
		display: none;
	}
	.value {
		display: block;
	}
}
