.progress{
    width: inherit;
    height: inherit;
}


.progress > div{
    width: inherit!important;
    height: inherit!important;
}

.progress span{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px!important;
    line-height: 21px!important;
    color: var(--text-color-1)!important;
}

.progress svg path:first-child{
    stroke: var(--border-color-10) !important;
}

.progress svg path:last-child{
    stroke: #F0AD00!important;
    filter: drop-shadow(0 0 3px #F0AD00);
}
