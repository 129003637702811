.component {
    background-color: var(--bg-color-1);
    box-shadow: 0 0 0.5px var(--shadow-color-1);
    padding-left: 0px;
    transition: transform ease 0.7s !important;
}

.component :global .ant-layout-sider-children {
    height: calc(100% - 80px);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
}
.component.expanded{
    padding: 0 10px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    padding: 0 24px 0;
}

.logo {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.blockLabel{
    margin: 20px 0;
    align-self: flex-start;
    padding: 0 32px;
    font-family: Poppins;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: var(--text-color-2);
}

.logo > svg {
    width: 24px;
    height: 24px;
}

.brandName {
    margin-left: 20px;
    font-weight: bold;
    color: var(--text-color-10);
    letter-spacing: 1px;
    font-size: 18px;
}

.brandName svg {
    width: 130px;
    height: 24px;
    color: var(--text-color-1);
}

.menuSeparator {
    width: 44px;
    height: 0px;
    margin: .3rem 1.3rem;
    border: 1px solid #5a8afc;
    align-self: flex-start;
    display: block;
}

.themeText {
    margin-left: 2rem;
}

.themeToggle {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0 0 24px 0;
    padding: 0 24px 0;
    color: var(--text-color-2);
}

.themeToggle svg {
    min-width: 24px;
    min-height: 24px;
    margin: 0 12px 0 0;
}

.expandBtn {
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 0 5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-2);
    transition: transform ease 0.7s !important;
}

.expandBtn svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    color: var(--text-color-4);
    transition: transform ease 0.7s !important;
    transform: rotate(180deg);
}

.expanded .expandBtn svg {
    transform: translate(6rem, 0) rotate(0deg);
    padding: 0;
}

.navList {
    position: relative;
    width: 100%;
}
