.component {
	/*background-color: var(--bg-color-2);*/
	/*box-shadow: 0 0 0.5px var(--shadow-color-1);*/
	/*border: 1px solid var(--bg-color-4);*/
	/*border-radius: 16px;*/
	/*margin-bottom: 32px;*/
	/*position: relative;*/
	/*padding: 0 30px 70px;*/
}

.preloader_custom {
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/*min-height: 60px;*/
	padding-bottom: 20px;
	/*border-bottom: 0px solid var(--bg-color-4);*/
}

.headerLabel {
	font-weight: bold;
	font-size: 21px;
	line-height: 26px;
	color: var(--text-color-3);
}

.filters > button,
.filters > button:hover,
.filters > button:active,
.filters > button:focus,
.filters > button:disabled,
.filters > button:disabled:hover {
	margin-left: 8px;
	/*background-color: var(--bg-color-4);*/
	border-radius: 24px;
	border: none;
}

.filters{
	display: flex;
	align-items: center;
	gap:20px;
	justify-content: flex-end;
	margin-left: auto;
}

.filters .filtersItem{
	display: flex;
	align-items: center;
	gap:10px;
	justify-content: flex-end;
}

.filters > button:nth-child(2) {
	margin-right: 34px;
}

.dropDownLabel {
	color: var(--text-color-4);
}
.ant-table-thead > tr > th{
	padding: 0 16px;
}
.table :global .ant-table-thead  > tr{
	min-height: 42px;
	border-radius: 8px;
}
.table :global .ant-table-thead > tr > th {
	/*padding: 16px 24px 16px;*/
	padding: 0 16px;
	font-style: normal;
	/*text-transform: uppercase;*/
	background-color: transparent;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	border: none;
	/*border-bottom: 1px solid var(--bg-color-4);*/
	color: var(--text-color-21);
}

.table :global .ant-table-tbody > tr > td {
	padding: 12px 16px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	border: none;
	background-color: var(--bg-color-15);
	/*border-bottom-color: var(--bg-color-4);*/
	color: var(--text-color-1);
}

.table :global .ant-table-tbody > tr > td:first-child {
	width: 250px;
}

.amountWrapper {
	display: flex;
	align-items: center;
}

.amountWrapper .icon {
	margin-right: 10px;
	display: flex;
}

.redLine {
	color: var(--border-color-2);
}

.firstLine {
	font-family: Poppins;
	font-weight: normal;
	font-size: 12px;
	color: var(--text-color-1);
}
.type{
	font-family: Poppins;
	text-transform: uppercase;
	color: var(--text-color-20);
	font-weight: 600;
}
.type.red{
	color: var(--text-color-22);
}

.table :global .ant-table-tbody > tr > td p {
	margin: 0;
}

.table :global .ant-table-tbody > tr:hover > td {
	/*background-color: var(--bg-color-4);*/
}

.table :global .ant-table-tbody > tr > td a {
	color: var(--text-color-20);
}

.table :global .ant-table-cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table :global .ant-table-footer {
	display: flex;
	justify-content: center;
	background-color: var(--bg-color-2);
}

.table :global .ant-table-pagination.ant-pagination {
	margin: 24px;
}

.table :global .ant-pagination-total-text {
	margin-right: auto;
	color: var(--text-color-1);
}

.table .icon svg {
	width: 24px;
	height: 24px;
}

.iconCol {
	max-width: 72px;
}

.emptyBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 80px 0;
}

.emptyLabel {
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: var(--text-color-3);
}

.moreBtn {
	width: 243px;
	height: 48px;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #fff;
}

.table :global .ant-table-footer .ant-btn {
	border-radius: 24px;
}

.startEarning {
	margin-top: 16px;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-16);
}

.createStakeBtn {
	margin-top: 32px;
	background: transparent;
	color: var(--text-color-3);
	border: 2px solid var(--text-color-3);
	border-radius: 100px;
	padding: 5px 30px 5px 30px;
	cursor: pointer;
}

.component :global .ant-spin-blur {
	opacity: 1;
}

.component :global .ant-spin-blur::after {
	background: transparent;
}

.component :global .ant-table {
	background: transparent;
}

.component :global .ant-table-content {
	overflow: hidden !important;
}

.component :global .ant-table-footer {
	border-radius: 0 0 16px 16px;
}

/* .component :global .ant-spin-nested-loading>div:first-child {
    display: none;
} */

.loadMoreBtn {
	background: transparent !important;
	color: var(--text-color-3) !important;
	border-color: var(--text-color-3) !important;
}

/* RESPONSIVE */
@media (max-width: 768px) {
	.component {
		margin-bottom: 0;
	}

	.header {
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}

	.headerLabel {
		padding: 16px;
	}

	.filters {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.filterDropDown {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.filters button {
		margin-left: 0;
	}

	.filters button + button {
		margin-top: 16px;
	}

	.table :global .ant-table-tbody > tr > td {
		font-size: 14px;
		color: var(--text-color-1);
	}
	.table :global .ant-table-tbody > tr > td:nth-child(2),
	.table :global .ant-table-thead > tr > th:nth-child(2){
		display: none;
	}

	.table :global .ant-pagination-total-text {
		display: none;
	}

	.component :global .ant-table-content {
		overflow-x: auto !important;
	}
}
@media (max-width:992px){
	.table :global .ant-table-tbody > tr > td{
		padding: 8px 10px;
	}
}
@media (max-width: 600px) {
	.table :global .ant-table-tbody > tr > td:nth-child(6),
	.table :global .ant-table-thead > tr > th:nth-child(6){
		display: none;
	}
	.emptyBlock {
		visibility: hidden;
	}
}

@media (max-width: 530px) {
	.table :global .ant-table-tbody > tr > td:nth-child(5),
	.table :global .ant-table-thead > tr > th:nth-child(5){
		display: none;
	}
}
