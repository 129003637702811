.balanceLabel {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-2);
}

.balanceTitile {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--text-color-3);
}

.balanceValue {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.row3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background-color: var(--bg-color-2);
    border-radius: 16px;
    border: 1px solid var(--bg-color-4);
}

.inputValue {
    width: 100%;
}

.inputMaxBtn,
.inputMaxBtn:hover,
.inputMaxBtn:focus {
    width: 100px;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: var(--text-color-3);
    background: transparent;
    border: 2px solid #344574;
    box-sizing: border-box;
    border-radius: 16px;
}

.tableWrapper {
    width: 100%;
}

.tableHeader {
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: var(--text-color-3);
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    top: 0px;
    flex: 1;
}

.tableHeader .balanceLabel {
    margin-bottom: 0px;
    font-size: 14px;
}

.tableHeader div span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.tableHeader div p {
    margin: 0;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: var(--text-color-1);
}

.tableRow {
    grid-template-columns: 2fr 2fr 2fr;
    flex: 0 1 100%;
    display: grid;
    align-items: center;
    color: var(--text-color-1);
    margin-bottom: 12px;
}

.tableRow div {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}
  
.tableRow a {
    color: var(--text-color-15);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
  
.tableRow:last-child {
    margin-bottom: 0;
}

.tableRow p {
    font-family: Mulish;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    color: var(--text-color-1);
}

.infoList {
    padding-inline-start: 20px;
    margin-top: 32px;
    color: var(--text-color-1);
    font-weight: bold;
    font-size: 14px;
}

.link {
    margin: 10px auto;
    font-weight: bold;    
    color: var(--text-color-15);
    cursor: pointer;
}

.balanceInfo {
    color: var(--text-color-2);
}

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}