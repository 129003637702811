.component {
	display: flex;
	margin-top: 15px;
	overflow: hidden;
}

.componentTable {
	margin-top: 15px;
}

.col {
	padding-left: 25px;
	padding-right: 25px;
	background-color: var(--bg-color-4);
	box-shadow: 0 8px 16px rgb(113 121 128 / 8%), 0 0 0.5px rgb(6 10 13 / 40%);
	border-radius: 16px;
	border: 2px solid var(--border-color-6);
	cursor: pointer;
	flex: 1;
	padding: 14px 16px 10px;
	max-height: 400px;
	cursor: not-allowed;
}

.title {
	min-width: 50%;
	display: flex;
	align-items: center;
}

.amount {
	min-width: 35%;
	display: flex;
	align-items: center;
}

.info {
	margin: 0 36px;
}

.infocont {
	margin: 15px -36px;
}

.col:last-child {
	cursor: pointer;
}

.changeWrapper {
	height: 150px;
}

.changeWrapper .component {
	margin-top: 0px;
	height: 100%;
	align-items: center;
}

.changeWrapper .component .col {
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.label {
	font-family: Mulish;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.04em;
	color: var(--text-color-3);
	text-align: center;
	margin-bottom: 10px;
}

.networkName {
	font-family: Mulish;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.04em;
	color: var(--text-color-5);
	text-align: center;
}

.swapIconWrapper {
	display: flex;
	padding: 20px;
	align-items: center;
}

.swapIconWrapper svg {
	width: 36px;
	height: 36px;
}

.logo {
	text-align: center;
	margin: 15px 0;
}

.logo svg {
	height: 100px;
	width: 100px;
}

.labelPools {
	margin-bottom: 16px;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 40px;
	color: var(--text-color-3);
}

.inputValue :global .ant-input {
	padding-left: 122px;
	text-align: center;
}

.tableHeader {
	margin-top: 26px;
	margin-left: 16px;
	padding-bottom: 10px;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: var(--text-color-3);
	border-bottom: 1px solid rgba(66, 96, 166, 0.2);
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
}

.fee {
	color: var(--text-color-5);
	display: flex;
	align-items: center;
	margin-bottom: 1px;
}

.feeVisible {
	left: 15px;
	transition: 0.5s;
}

.icon svg,
.icon {
	width: 16px;
	height: 16px;
	margin: 0 5px 0 2px;
}

.feeList {
	padding-inline-start: 20px;
	margin-bottom: 0em;
}

.feeList li::marker {
	color: var(--text-color-5);
}

.main {
	color: var(--text-color-15);
	font-weight: 600;
}

.percentCount {
	color: var(--text-color-17);
	font-weight: 600;
}

.wide {
	min-width: 85%;
}

@media (max-width: 1023px) {
	.feeList {
		padding-inline-start: 8px;
	}
	.title {
		min-width: 60%;
	}
	.wide {
		min-width: 95%;
	}
}

@media (max-width: 1000px) {
	.swapIconWrapper {
		padding: 20px;
	}
}

@media (max-width: 600px) {
	.inputValue :global .ant-input {
		padding-left: 15px;
		text-align: left;
	}
	.actionBtnWrapper {
		margin-top: 40px;
	}
	.fee {
		top: -35px;
	}
}
