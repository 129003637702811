.component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 64px;
    background-color: #ffce45;
}

.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.warnIcon svg {
    width: 24px;
    height: 24px;
    color: #997d3a;
}

.warnText {
    margin-left: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #665426;
}

.closeBtn svg {
    width: 16px;
    height: 16px;
    color: #997d3a;
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .component {
        align-items: flex-start;
        padding: 12px 16px;
    }
}
