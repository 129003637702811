.container {
  display: flex;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.titleReferral {
  padding-top: 27px;
  padding-bottom: 25px;
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  color: var(--text-color-1);
}

.information {
  font-size: 18px;
  line-height: 22.6px;
  font-weight: 400;
  color: var(--text-color-1);
  margin-bottom: 0rem;
}