.wrapper{
  display: flex;
  flex-direction: column;

}

.container {
  min-height: 100vh;
}

.main {
  position: relative;
  background-color: var(--bg-color-7);
}

.preSale {
  background-image: url(./../../resources/images/Background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}

:global .mobile-menu-open :local .main {
  max-height: 100vh;
  overflow: hidden;
}

.content {
  padding: 0 30px;
}

.footer {
  padding: 64px 0 0;
  background-color: transparent;
  text-align: right;
}

.footerLinks a {
  margin-left: 24px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color-4);
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .siderNav {
    display: none;
  }

  .content {
    padding: 0px 8px 20px;
  }

  .footer {
    display: none;
  }
}
