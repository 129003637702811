.swapButton {
    border: none;
    border-radius: 8px;
    display: flex;
    color: var(--text-color-5);
    background: var(--text-color-20);
    justify-content: space-between;
    font-weight: bold;
    height: 36px;
    padding: 16px 10px;
    width: 100px;
    align-items: center;
}
.swapButton span{
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
}

.swapButton:hover,
.swapButton:active,
.swapButton:focus {
    opacity: 0.8;
    color: var(--text-color-5);
    background: var(--text-color-20);
}
