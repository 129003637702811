.paginationBlock {
    display: flex;
    justify-content: space-between;
    /*position: absolute;*/
    margin: 20px 0;
    width: calc(100% - 36px);
    bottom: 20px;
  }

.paginationInfo {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-5);
}
