.component {
    background-color: var(--bg-color-15);
    border: none;
    border-radius: 8px;
    min-height: 129px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    /*height: 32px;*/
}

.label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: var(--text-color-21);
}

.value {
    margin: 10px 0;
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--text-color-1);
}

.hint {
    margin-top: auto;
    font-family: Poppins;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--text-color-21);
}

.widget{
    width: 100px;
    height: 100px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .component :global .ant-card-body {
        padding: 16px;
    }

    .value {
        margin-top: 16px;
        font-size: 14px;
        line-height: 24px;
    }

    .hint {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        color: var(--text-color-2);
    }
}
