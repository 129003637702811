.createStakeCloseModal {
  color: var(--text-color-1);
}

.createStakeCloseModal :global .ant-modal {
  color: var(--text-color-1);
}

.createStakeCloseModal :global .ant-modal-body {
  padding: 15px 16px 22px 16px;
}

.createStakeCloseModal :global .ant-modal-content {
  min-width: 700px;
  overflow: scroll;
}

.createStakeCloseModal :global .ant-modal-content::-webkit-scrollbar {
  display: none;
}

.stakingCloseForms {
  display: flex;
  flex-direction: column;
  color: var(--text-color-1);
}

.title {
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: var(--text-color-3);
}

.stakeCloseModalAlert {
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #8C2B45;
  border: 2px solid var(--bg-color-8);
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 15px;
}

.stakeCloseModalAlert svg {
  color: var(--bg-color-8);
  width: 28px;
}

.stakeCloseModalAlertMature {
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #107C70;
  border: 2px solid #00D26D;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 15px;
}

.stakeCloseModalAlertMature svg {
  color: #00D26D;
  width: 28px;
}

.message {
  margin-left: 25px;
  font-size: 16px;
}

.main {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.stakeProgress {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.stakeProgress svg {
  margin-right: 12px;
}

.stakingCloseLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg-color-2);
  border: none;
  box-sizing: border-box;
  border-radius: 16px;
  color: #00D26D;
  flex: 1;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.stakingCloseModalBtn {
  height: 31px;
  width: 179px;
  border-radius: 100px;
  padding: 6px, 32px, 6px, 32px;
  background: var(--bg-color-8);
  color: var(--text-color-1);
  font-weight: 700;
  font-size: 15px;
  border: none;
}

.stakingCloseModalMatureBtn {
  height: 31px;
  width: 179px;
  border-radius: 100px;
  padding: 6px, 32px, 6px, 32px;
  background: #00D26D;
  color: var(--bg-color-4);
  font-weight: 700;
  font-size: 15px;
  border: none;
}

.stakingCloseModalInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bg-color-2);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  font-size: 21px;
}

.stakingCloseModalInfoTitle {
  font-size: 16px;
  color: var(--text-color-3);
}

.description {
  width: 161px;
  font-size: 16px;
  color: var(--text-color-16);
}

.penaltyAmount {
  color: #FD8F77;
}

.stakingCloseRight {
  flex: 1;
  margin-left: 2px;
  display: inline-grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 2px;
}

.progress :global .ant-progress-text {
  color: #FD8F77 !important;
}

.progressDone :global .ant-progress-text {
  color: #00D26D !important;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.leftWrapper .stakingCloseModalInfo {
  margin-bottom: 2px;
}