.component {
  display: flex;
  margin-top: 15px;
}

.componentTable {
  margin-top: 15px;
}

.col {
  padding-left: 25px;
  padding-right: 25px;
  background-color: var(--bg-color-4);
  box-shadow: 0 8px 16px rgb(113 121 128 / 8%), 0 0 0.5px rgb(6 10 13 / 40%);
  border-radius: 16px;
  border: 2px solid var(--border-color-6);
  cursor: pointer;
  flex: 1;
  padding: 16px;
  max-height: 400px;
  cursor: not-allowed;
}

.col:last-child {
  cursor: pointer;
}

.changeWrapper {
  height: 300px;
}

.changeWrapper .component {
  margin-top: 0px;
  height: 100%;
  align-items: center;
}

.changeWrapper .component .col {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--text-color-3);
  text-align: center;
  margin-bottom: 10px;
}

.networkName {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--text-color-5);
  text-align: center;
}

.swapIconWrapper {
  display: flex;
  padding: 20px;
  align-items: center;
}

.swapIconWrapper svg{
  width: 36px;
  height: 36px;
}

.logo {
  text-align: center;
  margin: 15px 0;
}

.logo svg {
  height: 100px;
  width: 100px;
}

.labelPools {
  margin-bottom: 16px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  color: var(--text-color-3);
}

.inputValue :global .ant-input {
  padding-left: 122px;
  text-align: center;
}

.tableHeader {
  margin-top: 26px;
  margin-left: 16px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--text-color-3);
  border-bottom: 1px solid rgba(66, 96, 166, 0.2);
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
}

@media (max-width: 767px) {
  .componentTable {
    min-width: 400px;
    overflow-y: auto;
    padding: 0 7px 70px;
  }
}