.component {}

.headerLabel {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    text-decoration-line: underline;
    color: var(--text-color-3);
}

.dataRows {
    display: grid;
    grid-gap: 16px;
}

.tabs {
    margin: 32px 0 64px;
}

.tabs :global .ant-tabs-nav:before {
    border-bottom: none;
}

.tabs :global .ant-tabs-tab {
    color: var(--text-color-2);
}

.tabs :global .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--text-color-1);
}

.tabs :global .ant-tabs-nav-wrap {
    margin-left: 20px;
}

.table {
    margin-top: 24px;
}

.row1 {
    margin-top: 14px;
    background: var(--bg-color-4);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
    height: 84px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 32px;
}

.poolIcon {
    margin-right: 24px;
    display: flex;
    align-items: center;
}

.poolName {
    font-weight: bold;
    font-size: 21px;
    color: var(--text-color-1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.pool_epoch_countdown {
    text-transform: lowercase;
    color: var(--text-color-15);
    font-size: 14px;
}

@media (max-width: 450px) {
    .poolIcon {
        margin-right: 20px;
    }
    
    .poolName {
        font-size: 18px;
    }
}
