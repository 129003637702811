.connectBtn,
.connectBtn:hover,
.connectBtn:active,
.connectBtn:focus {
	background-color: var(--bg-color-15);
	font-size: 14px;
	border: none;
	border-radius: 8px;
	width: 208px;
	cursor: pointer;
	padding: 10px 16px;
	color: var(--text-color-1);
	font-family: Poppins;
	font-weight: 400;
}

.overlay {
	width: 320px;
}

/*
.overlay :global .ant-popover-inner-content {
    background-color: #181b29;
    border: 2px solid rgba(90, 138, 252, 0.4);
    border-radius: 16px;
}
*/

.disable {
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.3;
}

.overlay {
	background-color: #1f1f1f;
	/*border: 2px solid var(--border-color-6);*/
	border-radius: 16px;
	padding: 2px;
}

.overlay :global .ant-popover-arrow {
	border-color: var(--bg-color-2) !important;
}

.notificationBtn {
	cursor: pointer;
	color: var(--text-color-1);
	margin-right: 20px;
	width: 40px;
	padding: 8px;
	border-radius: 8px;
	background-color: var(--bg-color-15);

}

.notificationHeader {
	padding: 16px;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
}

.notificationBody {
	padding: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
}

.notificationZero {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	padding: 80px 0;
}

.notificationZero svg {
	width: 139px;
	height: 128px;
}

.notificationZero span {
	font-family: Mulish;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: var(--text-color-2);
}

.walletBox {
	display: flex;
	flex-direction: row;
}

.divider {
	border: 1px solid #4260a6;
	transform: rotate(180deg);
	height: 30px;
}

.walletPreview {
	background-color: var(--bg-color-15);
	font-size: 14px;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--text-color-1);
	font-family: Poppins;
	font-weight: 400;
	line-height: normal;
	width: 148px;
}

.walletPreviewAvatar {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	border-radius: 4px;
}

.walletPreviewHash {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-15);
}

.walletPreviewHashOnBtn {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1-1);
}

.walletPreviewArrow {
}

.walletHeader {
	margin: 10px 18px;
	display: flex;
}

.walletAvatarCol {
	color: #eef769;
}

.walletIconCoin {
	width: 30px;
}

.walletHash {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #4f6ae6;
}

.walletStatus {
	margin: 12px 18px 12px 16px;
}

.walletNavegation {
	margin: 24px 16px 0px 16px;
}

.walletNavegation a {
	text-decoration: none !important;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
}

.stats {
	padding-left: 20px;
	padding-right: 16px;
}

.statRow {
	color: #7f7f94;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.statCol {
	display: flex;
	margin: auto;
}

.statCol svg {
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	max-height: 30px;
	max-width: 30px;
}

.statRow :global .ant-col:nth-child(2) {
	flex-grow: 1;
}

.statRow + .statRow {
	margin-top: 0.8rem;
}

.statIcon {
	width: 24px;
	height: 24px;
	margin-right: 18px;
	color: var(--text-color-4);
}

.iconCol {
	display: flex !important;
	justify-content: end !important;
	margin: auto !important;
}

.poligonIcon {
	width: 13px;
	height: 8px;
	margin-top: 1px;
	color: var(--text-color-1);
}

.statName {
	flex-grow: 1;
	font-size: 16px;
	line-height: 24px;
	color: var(--text-color-2);
}
.navegationName {
	flex-grow: 1;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
	cursor: pointer;
}

.etherumCoin {
	flex-grow: 1;
	font-weight: 700;
	font-size: 16px;
	line-height: 26px;
	color: var(--text-color-1);
	margin: auto;
}

.swappCoin {
	flex-grow: 1;
	font-weight: 700;
	font-size: 16px;
	line-height: 26px;
	color: var(--text-color-1);
	margin: auto;
}

.statValue {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: var(--text-color-1);
}

.statTag {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #107c70;
}

.disconnectBtnRow {
	padding: 16px;
	padding-bottom: 18px;
	justify-content: center;
}

.disconnectBtn,
.disconnectBtn:hover {
	background: transparent;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	color: #fd8f77;
	border: 1px solid #fd8f77;
	border-radius: 100px;
	padding: 4px 28px;
}

.hidden {
	visibility: hidden;
}

.swappCoinCol {
	display: flex;
	margin: auto;
	padding-right: 7px;
}

.margin {
	margin-right: 2rem;
}

.connectorList {
	margin: 8px -4px;
	display: flex;
	flex-wrap: wrap;
}

.connectorBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	background: var(--bg-color-2);
	border: 2px solid var(--bg-color-4);
	box-sizing: border-box;
	border-radius: 8px;
	margin: 4px;
	flex: 1;
}

.connectorBtn svg {
	width: 40px;
	max-height: 40px;
	max-width: 45px;
}

.connectorBtn:hover {
	border: 2px solid var(--border-color-2);
	box-shadow: 0 16px 32px rgba(255, 67, 57, 0.04);
}

.connectorBtn:disabled,
.connectorBtn[disabled]:hover {
	background-color: var(--bg-color-6) !important;
	opacity: 0.5;
	border: 2px solid var(--border-color-4);
}

.connectorLogo {
	width: 32px;
	height: 32px;
}

.connectorName {
	margin-left: 6px;
	font-weight: bold;
	font-size: 16px;
	line-height: 32px;
	color: var(--text-color-1);
}

@media (max-width: 500px) {
	.connectBtn {
		width: 140px;
	}

	.walletPreviewHash {
		font-size: 12px;
		line-height: 24px;
	}
}

@media (max-width: 950px) {
	.walletPreview {
		height: 45px;
		min-height: 1px;
	}

	.notificationBtn {
		/*margin-top: 15px;*/
	}

	.walletBox {
		justify-content: flex-end;
	}
}
