.view {
}

.walletRow {
    position: relative;
    margin-bottom: 64px;
}

.divider {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: var(--bg-color-2);
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .walletRow {
        margin-bottom: 32px;
    }

    .wallet {
        position: absolute;
        right: 0;
        top: -5px;
        z-index: 999;
    }
}
