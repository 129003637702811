.component {
}

.headerLabel {
    margin-bottom: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--text-color-1);
}

.text {
    margin-bottom: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-2);
}

.switchBtn {
    width: 163px;
    height: 48px;
    margin-top: 8px;
    background-color: rgba(255, 67, 57, 0.1);
    border-radius: 4px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: var(--text-color-3);
}
