.component {
    width: 100%;
    height: 52px;
    background: var(--bg-color-1);
    border: 2px solid var(--bg-color-4);
    box-sizing: border-box;
    border-radius: 16px;
}

.component :global .ant-input-group-addon {
    background: transparent;
    border: none;
}

.component :global .ant-input {
    height: 100%;
    padding: 14px 16px;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color-3);
    border: none;
}

.component :global .ant-input:focus {
    box-shadow: none;
}
